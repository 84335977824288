<template>
    <v-list two-line color="grey lighten-3">
        <v-subheader><b>Dataset Data Type</b></v-subheader>
        <v-list-item>
            <v-list-item-icon>
                <v-icon color="indigo" x-large>
                    {{ selectedIcon  }}
                </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ selectedName }}</v-list-item-title>
                <v-list-item-subtitle>
                    {{ selectedGroupName  }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
                <v-dialog
                    max-width="600"
                    v-model="dialog"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                        >Select Datatype</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar
                            color="secondary"
                            dark
                        >Select Data Type</v-toolbar>
                        <v-card-text>
                            <v-list>
                                <v-list-group
                                    v-for="group in items"
                                    :key="group.name"
                                    v-model="group.active"
                                    :prepend-icon="group.icon"
                                    no-action
                                >
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="group.name" />
                                        </v-list-item-content>
                                    </template>

                                    <v-list-item
                                        v-for="child in group.items"
                                        :key="child.title"
                                        link
                                        @click="selectDatatype(group, child)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                v-if="child.icon"
                                            >
                                                {{ child.icon }}
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="child.name" />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-group>
                            </v-list>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                text
                                @click="dialog = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-list-item-icon>
        </v-list-item>
    </v-list>
</template>

<script>
    const datatypes = [
        {
            name: 'General',
            icon: 'fa-globe',
            items: [
                {
                    name: 'Product', dtype: 'csv', key: 'general-product-csv', icon: 'fas fa-file-csv',
                },
                {
                    name: 'Customer', dtype: 'csv', key: 'general-customer-csv', icon: 'fas fa-file-csv',
                },
            ],
        },
        {
            name: 'Google',
            icon: 'fab fa-google',
            items: [
                {
                    name: 'Add Spend', dtype: 'csv', key: 'google-addspend-csv', icon: 'fas fa-file-csv',
                },
            ],
        },
        {
            name: 'Facebook',
            icon: 'fab fa-facebook',
            items: [
                {
                    name: 'Add Spend', dtype: 'csv', key: 'facebook-addspend-csv', icon: 'fas fa-file-csv',
                },
            ],
        },
    ];

    export default {
        data: () => ({
            dialog: false,
            items: datatypes,
            selectedGroupName: undefined,
            selectedGroupIcon: undefined,
            selectedName: undefined,
            selectedIcon: undefined,
        }),
        methods: {
            selectDatatype(group, child) {
                this.selectedGroupName = group.name;
                this.selectedGroupIcon = group.icon;
                this.selectedName = `${group.name}: ${child.name}`;
                this.selectedIcon = child.icon;
                this.$emit('input', child.key);
                this.dialog = false;
            },
        },
    };
</script>
